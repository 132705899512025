/**
 * Created by sem on 09.06.17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localStorage from 'helpers/local-storage';

import * as actions from './redux';

const CONFIRMED_STORAGE_PROP = 'confirmedGeolocation';

export const GeolocationProp = PropTypes.shape({
  status: PropTypes.oneOf(['located', 'unlocated']),
  confirmed: PropTypes.bool,
  point: PropTypes.shape({
    type: PropTypes.oneOf(['Point']),
    coordinates: PropTypes.array,
  }),
  location: PropTypes.shape({
    id: PropTypes.integer,
    type: PropTypes.string,
    source: PropTypes.oneOf(['geoip', 'location']),
    parent: PropTypes.integer,
    display_path: PropTypes.string,
    geo_centroid: PropTypes.shape({
      type: PropTypes.oneOf(['Point']),
      coordinates: PropTypes.array
    }),
    bbox: PropTypes.shape({
      type: PropTypes.oneOf(['Polygon']),
      coordinates: PropTypes.array
    })
  })
});

let requesting = false;

export function geolocationConnect(params = {}) {
  return function wrapComponent(BaseComponent) {
    @connect(state => ({
      geolocation: state.geolocation,
    }), { ...actions }, null, { forwardRef: true })
    class GeolocationWrap extends Component {
      static propTypes = {
        geolocation: PropTypes.shape({
          loading: PropTypes.bool,
          loaded: PropTypes.bool,
          failure: PropTypes.bool,
          data: GeolocationProp
        }),
        actionGeolocate: PropTypes.func,
        // *** Automatic geolocate and inject location props
        geolocate: PropTypes.bool,
        geolocateBackend: PropTypes.bool,
        geolocateBrowser: PropTypes.bool,
      };

      static defaultProps = {
        geolocate: true,
        geolocateBackend: true,
        geolocateBrowser: false,
      };

      componentDidMount() {
        if (!this.props.geolocate) {
          return;
        }
        this.updateFromLandingIfNeeded();
        const { loading, loaded } = this.props.geolocation;
        if (!loaded && !loading && !requesting) {
          requesting = true;
          this.props.actionGeolocate(localStorage.get(CONFIRMED_STORAGE_PROP), false);
        }
      }

      componentDidUpdate() {
        const { loaded, failure } = this.props.geolocation;
        if (loaded || failure) {
          requesting = false;
        }
        this.updateFromLandingIfNeeded();
      }

      updateFromLandingIfNeeded() {
        const { landing = {}, geolocation } = this.props;
        const { loading, loaded, data: locData } = geolocation;
        const { position_info = {} } = landing;
        const { locality_locality_id: upd_id } = position_info;
        if (!loading && loaded && params && params.updateFromLanding && position_info) {
          if (locData && locData.location && locData.location.id !== upd_id) {
            // Do not confirm country
            if (upd_id != 1) {
              this.geolocationForceUpdate(upd_id);
            }
          }
        }
      }

      geolocationForceUpdate = (id) => {
        this.props.actionGeolocate(id, true);
        localStorage.put(CONFIRMED_STORAGE_PROP, id);
      }

      render() {
        const { geolocation, wRef, ...props } = this.props; // eslint-disable-line
        if (wRef) {
          props.ref = wRef;
        }

        if (!this.props.geolocate) {
          return React.createElement(
            BaseComponent,
            { ...props }
          );
        }

        return React.createElement(
          BaseComponent, {
            geolocationComplete: geolocation.loaded || geolocation.failure,
            geolocationForceUpdate: this.geolocationForceUpdate,
            geolocation: geolocation.data,
            ...props
          }
        );
      }
    }

    return GeolocationWrap;
  };
}

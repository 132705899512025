const stub = require('./stub');
const tracking = require('./tracking');

const ls = 'localStorage' in global && global.localStorage ? global.localStorage : stub;

function accessor(key, value) {
  if (arguments.length === 1) {
    return get(key);
  }
  return emit(key, value);
}

function get(key) {
  let result = ls.getItem(key);
  try {
    result = JSON.parse(result);
  } catch (e) {
    return result;
  }
  return result;
}

function emit(key, value) {
  let argument = value;
  if (typeof argument !== 'string') argument = JSON.stringify(argument);
  try {
    ls.setItem(key, argument);
    return true;
  } catch (e) {
    return false;
  }
}

function remove(key) {
  return ls.removeItem(key);
}

function clear() {
  return ls.clear();
}

accessor.emit = emit;
accessor.put = emit;
accessor.get = get;
accessor.remove = remove;
accessor.clear = clear;
accessor.subscribe = tracking.subscribe;
accessor.off = tracking.off;

export default accessor;

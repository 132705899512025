

let ms = {};

function getItem(key) {
  return key in ms ? ms[key] : null;
}

function setItem(key, value) {
  ms[key] = value;
  return true;
}

function removeItem(key) {
  const found = key in ms;
  if (found) {
    return delete ms[key];
  }
  return false;
}

function clear() {
  ms = {};
  return true;
}

module.exports = {
  getItem,
  setItem,
  removeItem,
  clear
};

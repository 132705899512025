/* eslint-disable */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import getDisplayName from 'react-display-name';
import clone from 'lodash/clone';
import each from 'lodash/each';

import DataLoader from './data-loader';

// Dict cache
let dictCache = {};
// Dict index cache
let dictIdxCache = {};

export default class Dict extends DataLoader {
  static propTypes = {
    ...DataLoader.propTypes
  };

  static defaultProps = {
    ...DataLoader.defaultProps,
    method: 'get',
    urlPrefix: 'dict/',
    injectPropName: 'dict',
    renderMethod: 'single'
  };

  static preload(params = {}) {
    return DataLoader._preload(Dict.defaultProps, params);
  }

  constructor(props, ctx) {
    super(props, ctx);
    if (props.preloaded) {
      dictCache[props.url] = props.preloaded;
    }
  }

  _langReload() {
    dictCache = {};
    super._langReload();
  }

  transformData(dictData = {}) {
    return super.transformData(dictData.data);
  }

  getMetadata(dictData) {
    const { url } = this.props;
    const { lang } = dictData;
    const key = `${lang}:${url}`;
    if (!dictIdxCache[key]) {
      dictIdxCache[key] = {};
      each(dictData.data, val =>
        dictIdxCache[key][val[dictData.key]] = val
      );
    }
    return {
      [`${this.props.injectPropName}Index`]: dictIdxCache[key]
    };
  }

  load(props) {
    const url = props.url;
    if (dictCache[url]) {
      this.setState({
        loading: false,
        loaded: true,
        error: null,
        reloadCount: this.state.reloadCount + 1,
        data: dictCache[url],
        rawData: props.dataModifier && clone(dictCache[url])
      });
      return Promise.resolve(dictCache[url]);
    }

    const promise = super.load(props);
    promise
      .then((response) => {
        if (response && response !== '$cancelled') {
          dictCache[url] = response;
        }
      });
    return promise;
  }
}

export function dictConnect(params) {
  return (BaseComponent) => {
    class ComponentWrappedWithDict extends Component {
      static displayName = `dictConnect(${getDisplayName(BaseComponent)})`;
      render() {
        return (
          <Dict {...params}>
            <BaseComponent {...this.props} />
          </Dict>
        );
      }
    }
    return ComponentWrappedWithDict;
  };
}

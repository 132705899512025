import each from 'lodash/each';

const listeners = {};
const listening = false;

function listen() {
  if (global.addEventListener) {
    global.addEventListener('storage', change, false);
  } else if (global.attachEvent) {
    global.attachEvent('onstorage', change);
  } else {
    global.onstorage = change;
  }
}

function change(event) {
  if (!event) {
    event = global.event; // eslint-disable-line
  }
  const all = listeners[event.key];
  if (all) {
    each(all, fire);
  }

  function fire(listener) {
    let newValue = event.newValue;
    let oldValue = event.oldValue;
    try {
      newValue = JSON.parse(newValue);
      oldValue = JSON.parse(oldValue);
    } catch (e) {
      return listener(newValue, oldValue, event.url || event.uri);
    }
    return listener(newValue, oldValue, event.url || event.uri);
  }
}

export function subscribe(key, fn) {
  if (listeners[key]) {
    listeners[key].push(fn);
  } else {
    listeners[key] = [fn];
  }
  if (listening === false) {
    listen();
  }
}

export function off(key, fn) {
  const ns = listeners[key];
  if (ns.length > 1) {
    ns.splice(ns.indexOf(fn), 1);
  } else {
    listeners[key] = [];
  }
}
